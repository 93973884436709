import Typography from "typography"

const typography = new Typography({
    baseFontSize: "18px",
    baseLineHeight: 1.5,
    bodyWeight: 400,
    bodyGray: 20,
    headerFontFamily: ["Vollkorn"],
    bodyFontFamily: ["Roboto"],
    googleFonts: [
        {
            name: 'Vollkorn',
            styles: [
                '400',
                '700',
            ],
        },
        {
            name: 'Roboto',
            styles: [
                '400',
                '700',
            ]
        }
],
    overrideStyles: ({
        adjustFontSizeTo,
        rhythm
    }, options, styles) => ({
        h3: {
            marginBottom: rhythm(1 / 3),
        },
        p: {
            marginBottom: rhythm(1 / 3),
        },
        h2: {
            fontWeight: 400,
            fontSize: rhythm(5 / 6),
            marginBottom: rhythm(3 / 6),
            letterSpacing: rhythm(1 / 10),
        },
        h1: {
            marginTop: rhythm(7 / 5),
        },
        h5: {
            marginTop: rhythm(7/5),
            marginBottom: rhythm(1 / 10),
            fontSize: rhythm(4 / 6),
            fontStyle: 'italic',
        }
    })
})

export default typography
