import React from "react"
import { Link } from "gatsby"
import "../styles/style.css"
import { SearchEngineOp } from "../components/SEO.js"
import { TypographyStyle } from "react-typography"
import typography from "../utils/typography"
import Logo from "../components/Logo.js"



const LayoutComponent = ({ children }) => (
  <>
<SearchEngineOp />
<TypographyStyle typography={typography} />
    <div className="easymed-base">
            <div className="sidebar">
                <div className="container sidebar-sticky">
                    <Link to="/"><Logo /></Link>
                    <div className="sidebar-about">
                        <h3>Ordrup Jagtvej 37,</h3>
                        <h3>2920 Charlottenlund,</h3>
                        <h3>Denmark.</h3>
                        <nav className="sidebar-nav">
                            <h2><Link to="/" className="sidebar-nav-item" activeClassName="active">Om Easymed</Link></h2>
                            <h2><Link to="/roentgen" className="sidebar-nav-item" activeClassName="active">Røntgen og CT</Link></h2>
                            <h2><Link to="/ultralyd" className="sidebar-nav-item" activeClassName="active">Ultralyd</Link></h2>
                            <h2><Link to="/mr-skanning" className="sidebar-nav-item" activeClassName="active">MR-skanning</Link></h2>
                            <h2><Link to="/straaleterapi" className="sidebar-nav-item" activeClassName="active">Stråleterapi</Link></h2>
                            <h2><Link to="/nukleaermedisin" className="sidebar-nav-item" activeClassName="active">Nukleærmedisin</Link></h2>
                            <h2><Link to="/simulatorer" className="sidebar-nav-item" activeClassName="active">Simulatorer</Link></h2>
                            <h2><a href="https://benjaminbroen.typeform.com/to/iSAlB3" className="sidebar-nav-item">Kontakt</a></h2>
                        </nav>
                        <h5>CVR 33369611</h5>
                        <a href="https://easymed.dk" aria-label="Easymed Denmark"><div className="flag danmark"></div></a>
                        <a href="https://easymed.com.se" aria-label="Easymed Sweden"><div className="flag sverige"></div></a>
                        <Link to="/" aria-label="Easymed Norway"><div className="flag norge"></div></Link>
                    </div>
                </div>
            </div>
        <div className="content container">
            <main>{children}</main>
        </div>
    </div>
  </>
)
export default LayoutComponent