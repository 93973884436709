import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const LogoComponent = () => {
  const data = useStaticQuery(graphql`
        query {
          file(relativePath: { eq: "easymed-logo.png"}) {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
`
)
  return (
      <GatsbyImage
        image={data.file.childImageSharp.gatsbyImageData}
        imgStyle={{
            objectFit: "contain"
        }}
        alt="easymed logo"
      />
  )
};

export default LogoComponent;